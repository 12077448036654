import React, { Component, Fragment } from "react";
import styled from "styled-components";
import logoDXC from "./assets/logo-dxc.png";
import logoHAL from "./assets/logo-hal.png";

export default class InputData extends Component {
  constructor(props) {
    super(props);
    this.state = { url: "", headers: [] };
    this.clickAddHeader = this.clickAddHeader.bind(this);
    this.clickBrowse = this.clickBrowse.bind(this);
    this.changeHeaderKey = this.changeHeaderKey.bind(this);
    this.changeHeaderValue = this.changeHeaderValue.bind(this);
    this.changeURL = this.changeURL.bind(this);
    this.clickDeleteHeader = this.clickDeleteHeader.bind(this);
  }
  clickAddHeader() {
    this.setState(prevState => ({
      headers: [...prevState.headers, { key: "", value: "" }]
    }));
  }

  clickBrowse() {
    const { triggerBrowse } = this.props;
    triggerBrowse(this.state.url, this.state.headers);
  }

  changeURL(e) {
    const url = e.target.value;
    this.setState(() => ({ url }));
  }

  changeHeaderKey(e, index) {
    const v = e.target.value;
    this.setState(prevState => ({
      headers: prevState.headers.map((header, i) =>
        i === index ? Object.assign({}, header, { key: v }) : header
      )
    }));
  }

  changeHeaderValue(e, index) {
    const v = e.target.value;
    this.setState(prevState => ({
      headers: prevState.headers.map((header, i) =>
        i === index ? Object.assign({}, header, { value: v }) : header
      )
    }));
  }

  clickDeleteHeader(index) {
    this.setState(prevState => ({
      headers: prevState.headers.filter((header, i) => i !== index)
    }));
  }

  render() {
    const { headers, url } = this.state;
    return (
      <InputDataStyled>
        <URLIconsContainer>
          <LogosContainer>
            <LogoDXC src={logoDXC} alt="Logo DXC" />
            <TitleHal>Halstack Browser</TitleHal>
          </LogosContainer>
          <LogoHAL src={logoHAL} alt="Logo HAL" />

          <UrlContainer>
            <InputURL
              onChange={this.changeURL}
              type="text"
              placeholder="API Base URL"
            />
            <SearchButton value={url} onClick={this.clickBrowse}>
              Browse API
            </SearchButton>
            <AddHeadersButton onClick={this.clickAddHeader}>
              Add headers
            </AddHeadersButton>
          </UrlContainer>
        </URLIconsContainer>
        {headers.length > 0 && (
          <HeadersContainer>
            <HeadersTitle>
              <HeadersTitleLabel>Headers</HeadersTitleLabel>
              <HeadersTitleButton onClick={this.clickAddHeader}>
                +
              </HeadersTitleButton>
            </HeadersTitle>
            <HeadersListContainer>
              {headers.map((header, hIndex) => (
                <HeaderContainer>
                  <InputHeaderKey
                    value={header.key}
                    type="text"
                    placeholder="Key"
                    onChange={e => {
                      this.changeHeaderKey(e, hIndex);
                    }}
                  />
                  <InputHeaderValue
                    value={header.value}
                    type="text"
                    placeholder="Value"
                    onChange={e => {
                      this.changeHeaderValue(e, hIndex);
                    }}
                  />
                  <DeleteHeaderButton
                    onClick={() => this.clickDeleteHeader(hIndex)}
                  >
                    -
                  </DeleteHeaderButton>
                </HeaderContainer>
              ))}
            </HeadersListContainer>
          </HeadersContainer>
        )}
      </InputDataStyled>
    );
  }
}

const InputDataStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const URLIconsContainer = styled.div`
  background-color: black;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const HeadersListContainer = styled.div`
  display: flex;
  width: calc(100% - 40px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeadersContainer = styled.div`
  width: 100%;
  background: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  margin-bottom: 50px;
`;

const LogoDXC = styled.img`
  width: 200px;
  align-self: flex-start;
  padding: 20px;
  padding-bottom: 0px;
`;

const LogosContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleHal = styled.h1`
  font-size: 25px;
  color: white;
  padding: 20px;
  padding-bottom: 0px;
  margin: 0px;
`;

const LogoHAL = styled.img`
  width: 120px;
  margin-bottom: 20px;
`;

const UrlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin-bottom: 30px;
`;

const SearchButton = styled.button`
  background-color: #8800ff;
  color: white;
  width: 100px;
  border: none;
  height: 35px;
  border-radius: 20px;
  margin: 0px 10px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const InputURL = styled.input`
  width: 100px;
  flex-grow: 1;
  border: none;
  height: 35px;
  background: #ffffff;
  padding: 0px 15px;
  border-radius: 20px;
  margin-left: 110px;
  &:focus {
    outline: 0;
  }
`;

const HeadersTitle = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 600px;
  margin: 20px 0px 15px;
  border-bottom: 1px solid #8800ff;
`;

const HeadersTitleLabel = styled.span`
  flex-grow: 1;
  line-height: 30px;
  text-transform: uppercase;
  color: #8800ff;
`;

const HeadersTitleButton = styled.button`
  width: 20px;
  background: #8800ff;
  border-radius: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  line-height: 12px;
  font-size: 12px;
  &:focus {
    outline: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 660px;
  margin-bottom: 10px;
`;

const InputHeaderKey = styled.input`
  flex-grow: 1;
  border: none;
  height: 40px;
  background: #d6d6d6;
  padding: 0px 9px;
  margin-right: 7px;
  margin-left: 30px;
`;

const InputHeaderValue = styled.input`
  flex-grow: 1;
  border: none;
  height: 40px;
  background: #d6d6d6;
  padding: 0px 9px;
`;

const AddHeadersButton = styled.button`
  background-color: #f0dfff;
  color: #8800ff;
  width: 100px;
  border: none;
  height: 35px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 20px;
  &:focus {
    outline: 0;
  }
`;

const DeleteHeaderButton = styled.button`
  background-color: #f0dfff;
  color: #8800ff;
  width: 20px;
  border: none;
  height: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 20px;
  margin-left: 10px;
  font-size: 15px;
  text-align: center;
  line-height: 15px;
  &:focus {
    outline: 0;
  }
`;
